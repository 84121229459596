import React, { useState, useEffect } from 'react';
import {
    Table,
    Modal,
    Button,
    Form,
    Row,
    Col,
    ListGroup,
} from 'react-bootstrap';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { FaFilter } from 'react-icons/fa';

const sampleData = [
    {
        "SCHOOL_YEAR": "2016-2017",
        "NCESSCH": "010000200277",
        "FIPST": 1,
        "LEAID": 100002,
        "ST_SCHID": "AL-210-0020",
        "LEA_NAME": "Alabama Youth Services",
        "SCH_NAME": "Sequoyah Sch - Chalkville Campus",
        "SCH_TYPE": "4",
        "SY_STATUS": "1",
        "LOCALE": "21",
        "CNTY": "01073",
        "NMCNTY": "Jefferson County",
        "STNAME": "Alabama",
        "STABBR": "AL",
        "GSLO": "07",
        "GSHI": "12",
        "LEVEL": "High",
        "CHARTER_TEXT": "No",
        "CCDNF": 1,
        "MEMBER": null,
        "FTE": 0.0,
        "FL_Z33S": "M",
        "FL_Z33SE": "M"
    },
    {
        "SCHOOL_YEAR": "2016-2017",
        "NCESSCH": "010000201667",
        "FIPST": 1,
        "LEAID": 100002,
        "ST_SCHID": "AL-210-0050",
        "LEA_NAME": "Alabama Youth Services",
        "SCH_NAME": "Camps",
        "SCH_TYPE": "4",
        "SY_STATUS": "1",
        "LOCALE": "41",
        "CNTY": "01001",
        "NMCNTY": "Autauga County",
        "STNAME": "Alabama",
        "STABBR": "AL",
        "GSLO": "07",
        "GSHI": "12",
        "LEVEL": "High",
        "CHARTER_TEXT": "No",
        "CCDNF": 1,
        "MEMBER": null,
        "FTE": 0.0,
        "FL_Z33S": "M",
        "FL_Z33SE": "M"
    }
    // Add more sample data as needed
];

const LeadGenerationPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState({
        state: '',
        schoolType: '',
        level: '',
        status: '',
        memberRange: ''
    });
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [savedSearches, setSavedSearches] = useState([]);
    const [showSavedSearchesModal, setShowSavedSearchesModal] = useState(false);
    const [showSaveSearchModal, setShowSaveSearchModal] = useState(false);
    const [newSearchName, setNewSearchName] = useState('');
    const [columnFilters, setColumnFilters] = useState({});
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [currentColumn, setCurrentColumn] = useState('');

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleFilterChange = (event) => {
        setFilters({
            ...filters,
            [event.target.name]: event.target.value
        });
    };

    const handleShowAdvancedFilters = () => setShowAdvancedFilters(!showAdvancedFilters);
    const handleSelectSchool = (school) => setSelectedSchool(school);
    const handleCloseSchoolModal = () => setSelectedSchool(null);
    const handleShowSavedSearches = () => setShowSavedSearchesModal(true);
    const handleCloseSavedSearches = () => setShowSavedSearchesModal(false);
    const handleShowSaveSearch = () => setShowSaveSearchModal(true);
    const handleCloseSaveSearch = () => {
        setNewSearchName('');
        setShowSaveSearchModal(false);
    };

    const handleSaveSearch = () => {
        if (newSearchName.trim() === '') {
            alert('Please enter a name for the search.');
            return;
        }
        const newSearch = {
            name: newSearchName,
            filters: { ...filters },
            searchTerm: searchTerm,
            columnFilters: { ...columnFilters }
        };
        setSavedSearches([...savedSearches, newSearch]);
        setNewSearchName('');
        handleCloseSaveSearch();
    };

    const handleApplySavedSearch = (search) => {
        setFilters({ ...search.filters });
        setSearchTerm(search.searchTerm || '');
        setColumnFilters({ ...search.columnFilters });
        handleCloseSavedSearches();
    };

    const handleFilterIconClick = (columnName) => {
        setCurrentColumn(columnName);
        setShowColumnFilterModal(true);
    };

    // Debounce search term
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    // Function to get filtered data excluding a specific column's filters
    const getFilteredDataExceptColumn = (excludeColumn) => {
        return sampleData.filter((school) => {
            // Global filters
            const matchesGlobalFilters =
                school.SCH_NAME.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) &&
                (filters.state ? school.STABBR === filters.state : true) &&
                (filters.schoolType ? school.SCH_TYPE === filters.schoolType : true) &&
                (filters.level ? school.LEVEL === filters.level : true) &&
                (filters.status ? school.SY_STATUS === filters.status : true) &&
                (filters.memberRange ? parseInt(school.MEMBER) <= parseInt(filters.memberRange) : true);

            // Column filters except the one for excludeColumn
            const matchesColumnFilters = Object.keys(columnFilters).every((column) => {
                if (column === excludeColumn) return true;
                if (!columnFilters[column] || columnFilters[column].length === 0) {
                    return true;
                }
                return columnFilters[column].includes(school[column]);
            });

            return matchesGlobalFilters && matchesColumnFilters;
        });
    };

    // Function to get unique values for a column based on current filters
    const getColumnUniqueValues = (columnName) => {
        const filteredData = getFilteredDataExceptColumn(columnName);
        const values = filteredData.map((item) => item[columnName]);
        return Array.from(new Set(values)).filter((v) => v != null);
    };

    // The main filtered data
    const filteredData = sampleData.filter((school) => {
        // Global filters
        const matchesGlobalFilters =
            school.SCH_NAME.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) &&
            (filters.state ? school.STABBR === filters.state : true) &&
            (filters.schoolType ? school.SCH_TYPE === filters.schoolType : true) &&
            (filters.level ? school.LEVEL === filters.level : true) &&
            (filters.status ? school.SY_STATUS === filters.status : true) &&
            (filters.memberRange ? parseInt(school.MEMBER) <= parseInt(filters.memberRange) : true);

        // Column filters
        const matchesColumnFilters = Object.keys(columnFilters).every((column) => {
            if (!columnFilters[column] || columnFilters[column].length === 0) {
                return true;
            }
            return columnFilters[column].includes(school[column]);
        });

        return matchesGlobalFilters && matchesColumnFilters;
    });

    const handleColumnFilterChange = (value) => {
        setColumnFilters((prevFilters) => {
            const columnFilter = prevFilters[currentColumn] || [];
            if (columnFilter.includes(value)) {
                // Remove the value
                const newFilter = columnFilter.filter((v) => v !== value);
                if (newFilter.length === 0) {
                    const { [currentColumn]: _, ...rest } = prevFilters;
                    return rest;
                } else {
                    return {
                        ...prevFilters,
                        [currentColumn]: newFilter,
                    };
                }
            } else {
                // Add the value
                return {
                    ...prevFilters,
                    [currentColumn]: [...columnFilter, value],
                };
            }
        });
    };

    const handleClearAllFilters = () => {
        setColumnFilters({});
    };

    const handleClearColumnFilter = () => {
        setColumnFilters((prevFilters) => {
            const { [currentColumn]: _, ...rest } = prevFilters;
            return rest;
        });
        setShowColumnFilterModal(false);
    };

    return (
        <React.Fragment>
            {/* Header */}
            <Header />

            {/* Main Content */}
            <div className="main main-app p-3 p-lg-4">
                <div className="content-wrapper">
                    <Row className="mb-4">
                        <Col>
                            <h4 className="main-title mb-0">School Lead Generation</h4>
                        </Col>
                    </Row>

                    <Form>
                        <Row className="mb-3">
                            <Col md={4}>
                                <Form.Group controlId="search">
                                    <Form.Label>Search by school name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter school name"
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="state">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="state"
                                        value={filters.state}
                                        onChange={handleFilterChange}
                                    >
                                        <option value="">All</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        {/* Add more states as needed */}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="schoolType">
                                    <Form.Label>School Type</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="schoolType"
                                        value={filters.schoolType}
                                        onChange={handleFilterChange}
                                    >
                                        <option value="">All</option>
                                        <option value="1">Public</option>
                                        <option value="2">Private</option>
                                        {/* Add more school types as needed */}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4}>
                                <Form.Group controlId="level">
                                    <Form.Label>Level</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="level"
                                        value={filters.level}
                                        onChange={handleFilterChange}
                                    >
                                        <option value="">All</option>
                                        <option value="Elementary">Elementary</option>
                                        <option value="Middle">Middle</option>
                                        <option value="High">High</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="d-flex justify-content-start mt-3">
                            <Button variant="primary" onClick={handleShowAdvancedFilters}>Advanced Filters</Button>
                            <Button variant="secondary" className="ml-2" onClick={handleShowSavedSearches}>
                                Saved Searches
                            </Button>
                            <Button variant="secondary" className="ml-2" onClick={handleShowSaveSearch}>
                                Save Search
                            </Button>
                            <Button variant="warning" className="ml-2" onClick={handleClearAllFilters}>
                                Clear All Filters
                            </Button>
                        </div>
                    </Form>

                    <Table striped bordered hover responsive className="mt-4">
                        <thead>
                            <tr>
                                <th>
                                    School Year
                                    <FaFilter
                                        onClick={() => handleFilterIconClick('SCHOOL_YEAR')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>
                                    School ID
                                    <FaFilter
                                        onClick={() => handleFilterIconClick('NCESSCH')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>
                                    District
                                    <FaFilter
                                        onClick={() => handleFilterIconClick('LEA_NAME')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>
                                    School Name
                                    <FaFilter
                                        onClick={() => handleFilterIconClick('SCH_NAME')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>
                                    County
                                    <FaFilter
                                        onClick={() => handleFilterIconClick('NMCNTY')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>
                                    State
                                    <FaFilter
                                        onClick={() => handleFilterIconClick('STABBR')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>
                                    Locale
                                    <FaFilter
                                        onClick={() => handleFilterIconClick('LOCALE')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                                <th>
                                    Level
                                    <FaFilter
                                        onClick={() => handleFilterIconClick('LEVEL')}
                                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((school, index) => (
                                <tr key={index}>
                                    <td>{school.SCHOOL_YEAR}</td>
                                    <td>{school.NCESSCH}</td>
                                    <td>{school.LEA_NAME}</td>
                                    <td>{school.SCH_NAME}</td>
                                    <td>{school.NMCNTY}</td>
                                    <td>{school.STABBR}</td>
                                    <td>{school.LOCALE}</td>
                                    <td>{school.LEVEL}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                {/* Footer */}
                <Footer />
            </div>

            {/* Modals */}
            {/* Advanced Filters Modal */}
            <Modal show={showAdvancedFilters} onHide={() => setShowAdvancedFilters(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Advanced Filters</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="memberRange">
                            <Form.Label>Member Range</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter max number of members"
                                value={filters.memberRange}
                                onChange={(e) => setFilters({ ...filters, memberRange: e.target.value })}
                            />
                        </Form.Group>
                        {/* Add more advanced filters as needed */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAdvancedFilters(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Saved Searches Modal */}
            <Modal show={showSavedSearchesModal} onHide={() => setShowSavedSearchesModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Saved Searches</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {savedSearches.length === 0 ? (
                        <p>No saved searches.</p>
                    ) : (
                        <ListGroup>
                            {savedSearches.map((search, index) => (
                                <ListGroup.Item
                                    key={index}
                                    action
                                    onClick={() => handleApplySavedSearch(search)}
                                >
                                    {search.name}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowSavedSearchesModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Save Search Modal */}
            <Modal show={showSaveSearchModal} onHide={() => setShowSaveSearchModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Save Search</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="searchName">
                            <Form.Label>Search Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter a name for your search"
                                value={newSearchName}
                                onChange={(e) => setNewSearchName(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowSaveSearchModal(false)}>Cancel</Button>
                    <Button variant="primary" onClick={handleSaveSearch}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Column Filter Modal */}
            <Modal
                show={showColumnFilterModal}
                onHide={() => setShowColumnFilterModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Filter {currentColumn.replace('_', ' ')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {getColumnUniqueValues(currentColumn).map((value) => (
                            <Form.Check
                                type="checkbox"
                                label={value || 'N/A'}
                                key={value}
                                checked={
                                    columnFilters[currentColumn]
                                        ? columnFilters[currentColumn].includes(value)
                                        : false
                                }
                                onChange={() => handleColumnFilterChange(value)}
                            />
                        ))}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClearColumnFilter}>
                        Clear Filter
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => setShowColumnFilterModal(false)}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default LeadGenerationPage;
