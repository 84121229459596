import React, { useState, useEffect } from 'react';
import { Form, Button, Card, Dropdown, InputGroup, FormControl } from 'react-bootstrap';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';

const AcademicBackground = () => {
  const [formData, setFormData] = useState({
    hs_name: '',
    cumulative_gpa_unweighted: '',
    class_rank: '',
    sat_score: '',
    act_cumulative_score: '',
    sat_math_score: '',
    sat_reading_writing_score: ''
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = localStorage.getItem('user');
      if (!user) {
        setMessage('No authentication token found');
        setLoading(false);
        return;
      }

      const { token } = JSON.parse(user);

      try {
        const response = await axios.get('https://api.student.youelite.net/api/user', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        const data = response.data;
        setFormData({
          has_name: data.hs_name || '',
          cumulative_gpa_unweighted: data.cumulative_gpa_unweighted || '',
          class_rank: data.class_rank || '',
          sat_score: data.sat_score || '',
          act_cumulative_score: data.act_cumulative_score || '',
          sat_math_score: data.sat_math_score || '',
          sat_reading_writing_score: data.sat_reading_writing_score || ''
        });
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        setMessage('Failed to load academic background');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    fetchSuggestions(query);
    setShowSuggestions(true);
  };

  const fetchSuggestions = debounce(async (query) => {
    if (!query) {
      setSuggestions([]);
      return;
    }

    const user = localStorage.getItem('user');
    if (!user) {
      return;
    }

    const { token } = JSON.parse(user);

    try {
      const response = await axios.get(`https://api.student.youelite.net/api/high-schools?search=${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setSuggestions(response.data);
    } catch (error) {
      console.error('Failed to fetch suggestions:', error);
    }
  }, 300);

  const handleSearchBlur = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 200);
  };

  const handleSearchFocus = () => {
    if (suggestions.length > 0) {
      setShowSuggestions(true);
    }
  };

  const handleSelectSchool = (schoolName) => {
    setFormData((prevData) => ({ ...prevData, hs_name: schoolName }));
    setSearchQuery(schoolName);
    setShowSuggestions(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = localStorage.getItem('user');
    if (!user) {
      setMessage('No authentication token found');
      return;
    }

    const { token } = JSON.parse(user);

    try {
      await axios.put('https://api.student.youelite.net/api/user', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      setMessage('Academic background updated successfully');
      setTimeout(() => {
        navigate('/college-preferences');
      }, 2000);
    } catch (error) {
      console.error('Failed to update academic background:', error);
      setMessage('Failed to update academic background');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-sign">
      <Card className="card-sign" style={{ maxWidth: '700px', margin: 'auto' }}>
        <Card.Header>
          <Link to="/" className="header-logo mb-4">YouElite</Link>
          <Card.Title>Academic Background</Card.Title>
          <Card.Text>Tell us about your academic achievements.</Card.Text>
        </Card.Header>
        <Card.Body>
          {message && <div className="alert alert-info">{message}</div>}
          <Form onSubmit={handleSubmit}>
            <div className="mb-3" style={{ position: 'relative' }}>
              <Form.Label>High School Name</Form.Label>
              <InputGroup>
                <FormControl
                  type="text"
                  placeholder="Enter your high school name"
                  name="hs_name"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  onBlur={handleSearchBlur}
                  onFocus={handleSearchFocus}
                />
              </InputGroup>
              {showSuggestions && suggestions.length > 0 && (
                <Dropdown.Menu show style={{
                  display: 'block',
                  width: '100%',
                  maxHeight: '300px',
                  overflowY: 'auto',
                  zIndex: 1000,
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                }}>
                  {suggestions.map((suggestion) => (
                    <Dropdown.Item key={suggestion.hs_name} onClick={() => handleSelectSchool(suggestion.hs_name)}>
                      {suggestion.hs_name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              )}
            </div>
            <div className="mb-3">
              <Form.Label>GPA</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your GPA" 
                name="cumulative_gpa_unweighted"
                value={formData.cumulative_gpa_unweighted} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="mb-3">
              <Form.Label>Class Rank</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your class rank" 
                name="class_rank"
                value={formData.class_rank} 
                onChange={handleChange} 
              />
            </div>
            <div className="mb-3">
              <Form.Label>SAT Score</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your SAT score" 
                name="sat_score"
                value={formData.sat_score} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="mb-3">
              <Form.Label>ACT Score</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your ACT score" 
                name="act_cumulative_score"
                value={formData.act_cumulative_score} 
                onChange={handleChange} 
              />
            </div>
            <div className="mb-3">
              <Form.Label>SAT Math Score</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your SAT Math score" 
                name="sat_math_score"
                value={formData.sat_math_score} 
                onChange={handleChange} 
              />
            </div>
            <div className="mb-3">
              <Form.Label>SAT English Score</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter your SAT English score" 
                name="sat_reading_writing_score"
                value={formData.sat_reading_writing_score} 
                onChange={handleChange} 
              />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">Save and Continue</Button>
          </Form>
          <Button variant="link" onClick={() => navigate('/college-preferences')} style={{color: '#007bff', textDecoration: 'underline', marginTop: '10px'}}>Skip</Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AcademicBackground;
