import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Form, ListGroup, Modal } from "react-bootstrap";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import ReactMarkdown from 'react-markdown';
import axios from "axios";
import styled from "styled-components";
import { FaQuestionCircle, FaCheckCircle, FaClock } from "react-icons/fa";

const StyledCard = styled(Card)`
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-radius: 12px;
  padding: 20px;
  background-color: #ffffff; /* Ensure default white background */
`;

const PreviousQuestionsCard = styled(Card)`
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-radius: 12px;
  background-color: #ffffff;
`;

const PreviousQuestionsCardHeader = styled(Card.Header)`
  padding: 10px 15px; /* Adjust padding to align the header content */
  margin: 0;
`;

const PreviousQuestionsCardBody = styled(Card.Body)`
  padding: 10px 15px; /* Adjust padding to align body content */
  margin: 0;
`;


const TokenCard = styled(StyledCard)`
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  color: white;
`;

const TokenCount = styled.h2`
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const QuestionCard = styled(StyledCard)`
  height: 100%; 
  background-color: ${({ isSubmitted }) => (isSubmitted ? "#4CAF50" : "#ffffff")}; /* Change background only when submitted */
  transition: background-color 0.5s ease; /* Smooth transition for background color */
  display: flex;
  flex-direction: column;
  justify-content: ${({ isSubmitted }) => (isSubmitted ? "center" : "flex-start")}; /* Center content if submitted */
  position: relative; /* Maintain layout */
  padding: 0; /* Remove default padding */
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 80vw; /* 80% of the viewport width */
  }
`;


const StyledTextArea = styled(Form.Control)`
  border-radius: 8px;
  border: 1px solid #ced4da;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  resize: vertical;
  min-height: 120px;
  margin-bottom: 1rem;
  width: 100%;  /* Ensure it spans the full width */
  max-width: 100%; /* Prevent overflow */
  
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(110, 142, 251, 0.25);
    border-color: #6e8efb;
  }
`;

const SubmitButton = styled(Button)`
  background-color: #6e8efb;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: bold;
  transition: all 0.3s ease;
  width: 100%;

  &:hover {
    background-color: #5a7ae2;
    transform: translateY(-2px);
  }
`;

const StyledListGroup = styled(ListGroup)`
  margin: 0; /* Ensure no extra margin */
  padding: 0; /* Ensure no padding */
  max-height: 300px;
  overflow-y: auto;
`;

const StyledListItem = styled(ListGroup.Item)`
  padding: 10px;  /* Reduce padding to bring the text closer to the edges */
  cursor: pointer;
  display: flex;
  align-items: center;  /* Ensure icon and text are aligned horizontally */
  gap: 5px; /* Reduce gap between icon and text to bring them closer */
  transition: background-color 0.2s;
  justify-content: flex-start; /* Ensure everything stays aligned to the left */

  &:nth-child(odd) {
    background-color: #f8f9fa;
  }

  &:hover {
    background-color: #e9ecef;
  }
`;



const StatusIcon = styled.span`
  margin-right: 10px;
  color: ${props => props.answered ? '#4CAF50' : '#FFA500'};
`;

export default function QuestionPage() {
    const [tokens, setTokens] = useState(0);
    const [newQuestion, setNewQuestion] = useState("");
    const [pastQuestions, setPastQuestions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);


    useEffect(() => {
        document.title = "Questions | YouElite";
        fetchTokens();
        fetchPastQuestions();
    }, []);

    // Fetch token balance
    const fetchTokens = async () => {
        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const response = await axios.get("https://api.student.youelite.net/api/user/tokens", {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            setTokens(response.data.token_balance);
        } catch (error) {
            console.error("Failed to fetch tokens", error);
        }
    };

    // Fetch past questions
    const fetchPastQuestions = async () => {
        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const response = await axios.get("https://api.student.youelite.net/api/approved-answers", {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            setPastQuestions(response.data);
        } catch (error) {
            console.error("Failed to fetch past questions", error);
        }
    };

    // Handle new question submission
    const handleNewQuestionSubmit = async () => {
      setLoading(true);
      try {
          const user = JSON.parse(localStorage.getItem("user"));
          await axios.post(
              "https://api.student.youelite.net/api/questions",
              { question: newQuestion },
              {
                  headers: {
                      Authorization: `Bearer ${user.token}`,
                  },
              }
          );
          setNewQuestion("");  // Clear the input
          setIsSubmitted(true); // Trigger success animation
  
          setTimeout(() => {
              setIsSubmitted(false);  // Revert back after 1 second
          }, 1000);
      } catch (error) {
          console.error("Failed to submit question", error);
          alert("Error submitting question");
      } finally {
          setLoading(false);
          fetchPastQuestions(); // Refresh past questions after submit
          fetchTokens();        // Refresh token balance
      }
    };
  

    // Open modal with selected question
    const handleQuestionClick = (question) => {
        setSelectedQuestion(question);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedQuestion(null);
    };

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <h4 className="main-title mb-4">Personalized Questions</h4>

                <Row className="g-4">
                    <Col md={4}>
                        <TokenCard>
                            <Card.Body className="d-flex flex-column align-items-center justify-content-center text-center">
                                <h6 className="mb-3">Tokens Left</h6>
                                <TokenCount>{tokens}</TokenCount>
                                <Button variant="light" className="mt-3" as="a" href="/purchase-tokens">
                                    Purchase Tokens
                                </Button>
                            </Card.Body>
                        </TokenCard>
                    </Col>
                    <Col md={8}>
                      <QuestionCard isSubmitted={isSubmitted}>
                          {!isSubmitted ? (
                              <>
                                  <Card.Header className="d-flex align-items-center">
                                      <FaQuestionCircle className="me-2" />
                                      <Card.Title as="h6" className="mb-0">Ask a New Question</Card.Title>
                                  </Card.Header>
                                  <Card.Body>
                                      <StyledTextArea
                                          as="textarea"
                                          placeholder="Type your question here. Make sure to be precise and detailed in what the issue is, what you need, and how you want the answer to be. The more precise you are, the better we can serve you."
                                          value={newQuestion}
                                          onChange={(e) => setNewQuestion(e.target.value)}
                                      />
                                      <SubmitButton
                                          onClick={handleNewQuestionSubmit}
                                          disabled={!newQuestion || loading}
                                      >
                                          {loading ? "Submitting..." : "Submit Question"}
                                      </SubmitButton>
                                  </Card.Body>
                              </>
                          ) : (
                              <div className="text-center">
                                  <FaCheckCircle size={48} color="white" />
                                  <h5 className="text-white mt-3">Question sent successfully!</h5>
                              </div>
                          )}
                      </QuestionCard>
                  </Col>


                </Row>

                <Row className="mt-3">
                  <Col>
                      <PreviousQuestionsCard>
                          <PreviousQuestionsCardHeader>
                              <Card.Title as="h6">Your Previous Questions</Card.Title>
                          </PreviousQuestionsCardHeader>
                          <PreviousQuestionsCardBody>
                              {pastQuestions.length > 0 ? (
                                <StyledListGroup>
                                  {pastQuestions.map((question) => (
                                      <StyledListItem key={question.id} onClick={() => handleQuestionClick(question)}>
                                          <StatusIcon answered={question.answer}>
                                              {question.answer ? <FaCheckCircle /> : <FaClock />}
                                          </StatusIcon>
                                          {question.question} {/* Display the question title */}
                                      </StyledListItem>
                                  ))}
                              </StyledListGroup>

                              ) : (
                                  <p className="text-muted">No previous questions available.</p>
                              )}
                          </PreviousQuestionsCardBody>
                      </PreviousQuestionsCard>
                  </Col>
              </Row>

              <StyledModal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedQuestion?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Question:</h5>
                    <ReactMarkdown>{selectedQuestion?.question || "No question text available."}</ReactMarkdown>
                    <h5>Answer:</h5>
                    <ReactMarkdown>{selectedQuestion?.answer || "No answer yet."}</ReactMarkdown>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
              </StyledModal>

  
                <Footer />
            </div>
        </React.Fragment>
    );
}