import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Badge, Card, Button } from "react-bootstrap";
import { Check } from 'react-feather';
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import "../assets/css/ArticlePage.css"

export default function ArticleDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isRead, setIsRead] = useState(false);

    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await axios.get(`/articles/${id}`);
                setArticle(response.data);
                setIsRead(response.data.is_read);
                setLoading(false);
                
                // Mark the article as read
                if (!response.data.is_read) {
                    await axios.post(`/articles/${id}/read`);
                    setIsRead(true);
                }
            } catch (error) {
                console.error("Error fetching article:", error);
                setLoading(false);
            }
        };

        fetchArticle();
    }, [id]);

    const toggleReadStatus = async () => {
        try {
            const endpoint = isRead ? `/articles/${id}/unread` : `/articles/${id}/read`;
            await axios.post(endpoint);
            setIsRead(!isRead);
        } catch (error) {
            console.error("Error toggling read status:", error);
        }
    };

    const goBack = () => {
        navigate(-1);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!article) {
        return <div>Error: Unable to fetch the article.</div>;
    }

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <div className="content-wrapper">
                    <Row className="mb-4">
                        <Col>
                            <Button variant="link" onClick={goBack}>&larr; Back to Guide</Button>
                            <h4 className="main-title mb-2">{article.title}</h4>
                            <p className="text-muted">{article.description}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <div className="article-content">
                                <p dangerouslySetInnerHTML={{ __html: article.body.replace(/\n/g, '<br />') }}></p>
                            </div>
                        </Col>
                        <Col md={4}>
                            <Card className="article-sidebar">
                                <Card.Body>
                                    <h5>Article Information</h5>
                                    <p><strong>Created at:</strong> {new Date(article.created_at).toLocaleDateString()}</p>
                                    
                                    <div className="read-status mt-3">
                                    <Button 
                                        variant={isRead ? "success" : "outline-secondary"}
                                        onClick={toggleReadStatus}
                                        className="d-flex align-items-center"
                                        style={{
                                            backgroundColor: !isRead ? '#f8f9fa' : '', // Adjust the background color for "Mark as Read"
                                            color: !isRead ? '#6c757d' : '', // Ensure the text is visible
                                            borderColor: '#6c757d' // Darken the border color for visibility
                                        }}
                                    >
                                        <Check size={18} className="me-2" />
                                        {isRead ? "Read" : "Mark as Read"}
                                    </Button>

                                    </div>

                                    {article.tags.length > 0 && (
                                        <div className="article-tags mt-3">
                                            <h6>Tags:</h6>
                                            {article.tags.map((tag, index) => (
                                                <Badge key={index} bg="secondary" className="me-2">
                                                    {tag}
                                                </Badge>
                                            ))}
                                        </div>
                                    )}
                                    
                                    {article.related.length > 0 && (
                                        <div className="related-articles mt-3">
                                            <h6>Related Articles:</h6>
                                            <ul>
                                                {article.related.map((relatedArticle, index) => (
                                                    <li key={index}>{relatedArticle}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Footer />
            </div>
        </React.Fragment>
    );
}