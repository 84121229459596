import React, { useEffect, useState } from "react";
import { Accordion, Row, Col, Form, Breadcrumb, Nav } from "react-bootstrap";
import Footer from "../layouts/Footer";
import HeaderMobile from "../layouts/HeaderMobile";
import axios from "axios";

export default function Faq() {
  const [faqs, setFaqs] = useState([]);
  const [groupedFaqs, setGroupedFaqs] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    // Fetch all FAQs
    axios.get('/faqs')
      .then(response => {
        const fetchedFaqs = response.data;
        setFaqs(fetchedFaqs);

        // Group FAQs by category
        const grouped = fetchedFaqs.reduce((acc, faq) => {
          const category = faq.category;
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(faq);
          return acc;
        }, {});

        setGroupedFaqs(grouped);

        // Extract categories
        const uniqueCategories = Object.keys(grouped);
        setCategories(uniqueCategories);
      })
      .catch(error => {
        console.error("There was an error fetching the FAQs!", error);
      });
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    
    // Fetch FAQs for the selected category
    axios.get(`/faqs-category/${category}`)
      .then(response => {
        setFaqs(response.data);
      })
      .catch(error => {
        console.error(`There was an error fetching FAQs for category ${category}!`, error);
      });
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <div className="main main-faq p-4 p-lg-5">
        <Breadcrumb className="fs-sm mb-2">
          <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item href="/support">Support</Breadcrumb.Item>
          <Breadcrumb.Item active>FAQ</Breadcrumb.Item>
        </Breadcrumb>
        <h2 className="main-title mb-3">Frequently Asked Questions</h2>

        <Row className="g-5">
          <Col xl>
            <p className="text-secondary mb-4">
              Welcome to our Frequently Asked Questions (FAQ) page, where you'll find answers to the most common inquiries about our services, policies, and more.
            </p>

            <div className="form-search py-2 mb-5" style={{display:"none"}}>
              <i className="ri-search-line"></i>
              <Form.Control type="text" placeholder="Search" />
            </div>

            {Object.keys(groupedFaqs).map((category, index) => (
              <div key={index}>
                <h4 className="mb-3">{category}</h4>
                <Accordion className="accordion-faq mb-4">
                  {groupedFaqs[category].map((faq, idx) => (
                    <Accordion.Item eventKey={idx.toString()} key={faq.id}>
                      <Accordion.Header>{faq.title}</Accordion.Header>
                      <Accordion.Body>
                        <p>{faq.description}</p>
                        <p>{faq.body}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            ))}
          </Col>

          <Col md="6" lg="7" xl="4" xxl="3">
            <h5 className="section-title mb-4">Filter by Categories</h5>
            <Nav className="nav-classic flex-column">
              {categories.map((category, index) => (
                <Nav.Link key={index} onClick={() => handleCategoryClick(category)}>
                  <span>{category}</span>
                </Nav.Link>
              ))}
            </Nav>

            {selectedCategory && (
              <div>
                <h5 className="section-title mt-5 mb-4">{selectedCategory} Articles</h5>
                <Accordion defaultActiveKey="0" className="accordion-faq">
                  {faqs.map((faq, idx) => (
                    <Accordion.Item eventKey={idx.toString()} key={faq.id}>
                      <Accordion.Header>{faq.title}</Accordion.Header>
                      <Accordion.Body>
                        <p>{faq.description}</p>
                        <p>{faq.body}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            )}
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}
