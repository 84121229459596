import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button, Card, Col, Row, Form } from "react-bootstrap";
import styled from "styled-components";
import lottie from 'lottie-web';
import ReactGA from 'react-ga4';
import { loadStripe } from "@stripe/stripe-js";
import { RiQuestionnaireLine, RiSendPlaneLine, RiTeamLine, RiDraftLine } from 'react-icons/ri';
import { FaCheckCircle, FaQuestionCircle } from "react-icons/fa";
import axios from "axios";
import ReactFlow, {
  Handle,
  Background,
  ReactFlowProvider,
  getMarkerEnd,
} from 'reactflow';
import 'reactflow/dist/style.css';

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}

const StyledCard = styled(Card)`
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: transform 0.3s ease;
  max-width: 220px;

  @media (max-width: 576px) {
    max-width: 100%;
  }
`;


const QuestionCard = styled(StyledCard)`
  background-color: ${({ isSubmitted }) => (isSubmitted ? "#4CAF50" : "#ffffff")};
  transition: background-color 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: ${({ isSubmitted }) => (isSubmitted ? "center" : "flex-start")};
  padding: 0;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 576px) {
    max-width: 100%;
  }
`;



const StyledTextArea = styled(Form.Control)`
  border-radius: 8px;
  border: 1px solid #ced4da;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 10px; /* Ensure the same padding as StyledInput */
  font-size: 14px;
  resize: vertical;
  min-height: 120px;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 100%;
`;


const SubmitButton = styled(Button)`
  background-color: #6e8efb;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: bold;
  transition: all 0.3s ease;
  width: 100%;
  
  &:hover {
    background-color: #5a7ae2;
    transform: translateY(-2px);
  }
`;

const GuestQuestionPageWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const StyledInput = styled(Form.Control)`
  border-radius: 8px;
  border: 1px solid #ced4da;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 10px;
  font-size: 14px;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 100%;

  &::placeholder {
    color: #737474; 
    opacity: 1; /* Ensures the color is not dimmed */
  }
`;





const GuestQuestionPage = () => {
  const [newQuestion, setNewQuestion] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const featuresSectionRef = useRef(null);

  const applicationAnimationContainer = useRef(null);
  const collegeListContainer = useRef(null);
  const collegeDiscoveryContainer = useRef(null);

  useEffect(() => {
    document.title = "Ask Your Question | YouElite";

    let animApp;
    if (applicationAnimationContainer.current) {
      animApp = lottie.loadAnimation({
        container: applicationAnimationContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });
    }

    let animList;
    if (collegeListContainer.current) {
      animList = lottie.loadAnimation({
        container: collegeListContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });
    }

    let animDiscovery;
    if (collegeDiscoveryContainer.current) {
      animDiscovery = lottie.loadAnimation({
        container: collegeDiscoveryContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });
    }

    return () => {
      animApp && animApp.destroy();
      animList && animList.destroy();
      animDiscovery && animDiscovery.destroy();
    };
  }, []);

  const handleSubmitQuestion = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        "https://api.student.youelite.net/api/guest-create-token-checkout-session",
        {
          email,
          question: newQuestion,
          bundle: "price_1Q5vZt05p8417GVDaF8TvRFz", 
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      

      const sessionId = response.data.id;
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
      stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error("Error during checkout", error);
      alert("Error during checkout");
    } finally {
      setLoading(false);
    }
  };

  const scrollToFeatures = () => {
    if (featuresSectionRef.current) {
      featuresSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  
  const DiagramSection = () => {
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768;
  
    useEffect(() => {
      const reactFlowAttribution = document.querySelector('.react-flow__attribution');
      if (reactFlowAttribution) {
        reactFlowAttribution.style.display = 'none';
      }
    }, []);
  
    const nodes = useMemo(() => {
      if (isMobile) {
        return [
          {
            id: '1',
            type: 'customNode',
            position: { x: 20, y: 20 },
            data: {
              title: 'Write question',
              description: 'Ask any college question on your mind!',
              icon: <RiQuestionnaireLine />,
            },
          },
          {
            id: '2',
            type: 'customNode',
            position: { x: 20, y: 180 },
            data: {
              title: 'Submit',
              description: 'Enter your email and send your question — no login required',
              icon: <RiSendPlaneLine />,
            },
          },
          {
            id: '3',
            type: 'customNode',
            position: { x: 20, y: 340 },
            data: {
              title: 'Our Experts',
              description: 'Top experts review your question for a precise answer!',
              icon: <RiTeamLine />,
            },
          },
          {
            id: '4',
            type: 'customNode',
            position: { x: 20, y: 500 },
            data: {
              title: 'Polished Answer',
              description: 'Receive a detailed answer in max 24 hours!',
              icon: <RiDraftLine />,
            },
          },
        ];
      } else {
        return [
          {
            id: '1',
            type: 'customNode',
            position: { x: 100, y: 100 },
            data: {
              title: 'Write question',
              description: 'Ask any college question on your mind!',
              icon: <RiQuestionnaireLine />,
            },
          },
          {
            id: '2',
            type: 'customNode',
            position: { x: 350, y: 250 },
            data: {
              title: 'Submit',
              description: 'Enter your email and send your question — no login required',
              icon: <RiSendPlaneLine />,
            },
          },
          {
            id: '3',
            type: 'customNode',
            position: { x: 600, y: 100 },
            data: {
              title: 'Our Experts',
              description: 'Top experts review your question for a precise answer!',
              icon: <RiTeamLine />,
            },
          },
          {
            id: '4',
            type: 'customNode',
            position: { x: 850, y: 250 },
            data: {
              title: 'Polished Answer',
              description: 'Receive a detailed answer in max 24 hours!',
              icon: <RiDraftLine />,
            },
          },
        ];
      }
    }, [isMobile]);
  
    const edges = useMemo(() => {
      return [
        { id: 'e1-2', source: '1', target: '2', type: 'wiggly', markerEnd: 'arrowclosed' },
        { id: 'e2-3', source: '2', target: '3', type: 'wiggly', markerEnd: 'arrowclosed' },
        { id: 'e3-4', source: '3', target: '4', type: 'wiggly', markerEnd: 'arrowclosed' },
      ];
    }, []);
  
    const CustomNodeComponent = ({ data }) => {
      return (
        <StyledCard>
          <Handle
            type="target"
            position="top"
            style={{ opacity: 0 }} 
          />
          <Card.Body>
            <h5 style={{ fontWeight: 'bold', fontSize: '18px', display: 'flex', alignItems: 'center' }}>
              {data.icon && <span style={{ marginRight: '8px' }}>{data.icon}</span>}
              {data.title}
            </h5>
            <p style={{ color: '#000000', fontSize: '16px' }}>{data.description}</p>
          </Card.Body>
          <Handle
            type="source"
            position="bottom"
            style={{ opacity: 0 }}
          />
        </StyledCard>
      );
    };
  
    const nodeTypes = {
      customNode: CustomNodeComponent,
    };
  
    const WigglyEdge = ({
      id,
      sourceX,
      sourceY,
      targetX,
      targetY,
      markerEnd,
    }) => {
      const edgePath = isMobile
        ? `M${sourceX},${sourceY} L${targetX},${targetY}`
        : `M${sourceX},${sourceY} Q ${(sourceX + targetX) / 2},${sourceY - 100} ${targetX},${targetY}`;
      const markerEndUrl = getMarkerEnd(markerEnd);
  
      return (
        <>
          <path
            id={id}
            d={edgePath}
            stroke="#000"
            fill="none"
            markerEnd={markerEndUrl}
            strokeWidth={2}
            className="react-flow__edge-path"
          />
        </>
      );
    };
  
    const edgeTypes = {
      wiggly: WigglyEdge,
    };
  
    return (
      <div style={{ height: isMobile ? '600px' : '300px', width: '100%' }}>
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            nodesDraggable={false}
            nodesConnectable={false}
            elementsSelectable={false}
            zoomOnScroll={false}
            zoomOnPinch={false}
            panOnScroll={false}
            panOnDrag={false}
            preventScrolling={false}
            defaultCursor="default"
            fitView
            fitViewOptions={{ padding: 0.1 }}
          >
          </ReactFlow>
        </ReactFlowProvider>
      </div>
    );
  };
  

  

  return (
    <GuestQuestionPageWrapper>
      <Row className="g-0"
        style={{
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh', 
        }}
        >

        <Col
          md="7"
          lg="5"
          xl="4"
          className="col-wrapper d-none d-lg-flex"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          <QuestionCard isSubmitted={isSubmitted}>
            {!isSubmitted ? (
              <>
                <Card.Header className="d-flex align-items-center">
                  <FaQuestionCircle className="me-2" />
                  <Card.Title as="h6" className="mb-0">Ask a New Question</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form>
                    <div className="mb-3">
                      <Form.Label>Email address</Form.Label>
                      <StyledInput
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <StyledTextArea
                      as="textarea"
                      placeholder="Type your question here. Be precise and detailed."
                      value={newQuestion}
                      onChange={(e) => setNewQuestion(e.target.value)}
                      required
                    />
                    <SubmitButton
                      onClick={handleSubmitQuestion}
                      disabled={!newQuestion || !email || loading}
                      variant="primary"
                    >
                      {loading ? "Processing..." : "Submit for $1.99 - Get Your Answer Now"}
                    </SubmitButton>
                    <div className="mt-2">
                        <small>
                          <strong>100%</strong> secured payments with <strong>Stripe</strong>. We don't store your card information.{' '}
                        </small>
                    </div>
                  </Form>
                </Card.Body>
              </>
            ) : (
              <div className="text-center">
                <FaCheckCircle size={48} color="white" />
                <h5 className="text-white mt-3">Question sent successfully!</h5>
              </div>
            )}
          </QuestionCard>
        </Col>

        <Col
          className="p-5"
          style={{
            backgroundColor: "#fcfbfb",
            borderLeft: "1px solid #e0e0e0",
            height: "100vh",
            overflowY: "auto",
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div className="header-section" style={{ textAlign: "center", marginBottom: "20px" }}>
            <h1 style={{ fontWeight: "bold", fontSize: "34px", marginBottom: "10px" }}>
            Get Affordable, Expert College Advice in 24 Hours
            </h1>
            <p style={{ fontWeight: "bold", fontSize: "16px", display:"none" }}>
              Join thousands of students who are preparing their applications. Start today to get ahead of the competition!
            </p>
          </div>

          <div className="button-section" style={{ textAlign: "center", marginBottom: "40px", display:"none" }}>
            <Button variant="primary" style={{ marginRight: "10px", padding: "10px 20px" }}>
              Get Started for Free—Take Control of Your College Journey!
            </Button>
            <Button
              variant="outline-dark"
              style={{ padding: "10px 20px" }}
              onClick={scrollToFeatures}
            >
              Explore Features
            </Button>
          </div>

          <div ref={featuresSectionRef}>
          <div
              className="additional-features-section"
              style={{
                marginTop: window.innerWidth < 768 ? '10px' : '20px',
              }}
            >
              <h2 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '10px', display: 'none'}}>
                How It Works: Simple, Fast, Affordable Admissions Help
              </h2>
              <p style={{ color: '#000000', fontSize: '16px', textAlign: 'center', display: "none" }}>
                Get clear, personalized answers to your admissions questions in just a few clicks. No account needed – just expert advice delivered straight to your inbox.
              </p>
              <DiagramSection />
              {/* QuestionCard displayed only on mobile */}
              <div className="d-block d-lg-none" style={{ marginTop: '20px' }}>
                <QuestionCard isSubmitted={isSubmitted}>
                  {!isSubmitted ? (
                    <>
                      <Card.Header className="d-flex align-items-center">
                        <FaQuestionCircle className="me-2" />
                        <Card.Title as="h6" className="mb-0">Ask a New Question</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Form>
                          <div className="mb-3">
                            <Form.Label>Email address</Form.Label>
                            <StyledInput
                              type="email"
                              placeholder="Enter your email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>
                          <StyledTextArea
                            as="textarea"
                            placeholder="Type your question here. Be precise and detailed."
                            value={newQuestion}
                            onChange={(e) => setNewQuestion(e.target.value)}
                            required
                          />
                          <SubmitButton
                            onClick={handleSubmitQuestion}
                            disabled={!newQuestion || !email || loading}
                            variant="primary"
                          >
                            {loading ? "Processing..." : "Submit for $1.99 - Get Your Answer Now"}
                          </SubmitButton>
                          <div className="mt-2">
                            <small>
                              <strong>Stripe</strong>-secured checkout. We don't store your card information.
                            </small>
                          </div>
                        </Form>
                      </Card.Body>
                    </>
                  ) : (
                    <div className="text-center">
                      <FaCheckCircle size={48} color="white" />
                      <h5 className="text-white mt-3">Question sent successfully!</h5>
                    </div>
                  )}
                </QuestionCard>
              </div>

              <p style={{ color: '#000000', fontSize: '16px', textAlign: 'center', marginTop:'10px' }}>
                <strong>Why pay for this?</strong> For a fraction of the cost, get expert answers tailored to your questions—no long waits, no need for full consulting packages. Just affordable, personalized advice when you need it.
              </p>

              <div className="trusted-section" style={{ textAlign: "center", marginTop: "20px", marginBottom: "1px" }}>
                <p style={{ color: "#6c757d", marginBottom: "10px", fontSize: "14px" }}>
                  Trusted by students all over the US
                </p>
                <div className="logos">
                  <Row className="justify-content-center align-items-center">
                    <Col xs="6" sm="4" md="3" lg="2" className="d-flex justify-content-center mb-3">
                      <img 
                        src={`${process.env.PUBLIC_URL}/images/benzinga.png`} 
                        alt="Benzinga Logo" 
                        style={{ maxWidth: "100px", height: "auto" }} 
                      />
                    </Col>
                    <Col xs="6" sm="4" md="3" lg="2" className="d-flex justify-content-center mb-3">
                      <img 
                        src={`${process.env.PUBLIC_URL}/images/smaller_ap.png`} 
                        alt="AP News Logo" 
                        style={{ maxWidth: "100px", height: "auto" }} 
                      />
                    </Col>
                    <Col xs="6" sm="4" md="3" lg="2" className="d-flex justify-content-center mb-3">
                      <img 
                        src={`${process.env.PUBLIC_URL}/images/florida_news.svg`} 
                        alt="Florida News Logo" 
                        style={{ maxWidth: "100px", height: "auto" }} 
                      />
                    </Col>
                    <Col xs="6" sm="4" md="3" lg="2" className="d-flex justify-content-center mb-3">
                      <img 
                        src={`${process.env.PUBLIC_URL}/images/world_education.svg`} 
                        alt="World Education Logo" 
                        style={{ maxWidth: "150px", height: "auto" }} 
                      />
                    </Col>
                  </Row>
                </div>
              </div>

            </div>
          </div>
          
          <div className="ui-example-section" style={{ marginTop: "20px", display: "none"}}>
            <Row>
              <Col style={{ padding: "20px" }}>
                <h4 style={{ fontWeight: "bold" }}>Get the Answers That Matter</h4>
                <p style={{ color: "#000000", fontSize: "14px" }}>
                Whether it’s about perfecting your personal statement, standing out in a competitive applicant pool, or understanding financial aid, ask the questions that matter most to you. We’ll deliver personalized advice to help you stand out and succeed.
                </p>
              </Col>
              <Col style={{ padding: "20px" }}>
              </Col>
            </Row>
          </div>

          <div className="additional-components-section" style={{ marginTop: "20px" }}>
            <Row>
              <Col style={{ padding: "20px" }}>
                <h4 style={{ fontWeight: "bold" }}>Real Experts, Real Answers</h4>
                <p className="mt-1" style={{ color: "#000000", fontSize: "14px" }}>Our college admissions experts know what it takes to succeed. With years of experience and insider knowledge, they’ll provide answers that are specific to your situation. You’ll receive clear, actionable advice you can trust.</p>
              </Col>

              <Col style={{ padding: "20px" }}>
                <h4 style={{ fontWeight: "bold" }}>Expert Answers on Your Schedule</h4>
                <p className="mt-1" style={{ color: "#000000", fontSize: "14px" }}>Have a question at 2 a.m.? We’ve got you covered. Get fast, expert feedback whenever you need it. No long waits or delays—just reliable advice, when you need it most.</p>
              </Col>
              <Col style={{ padding: "20px" }}>
                <h4 style={{ fontWeight: "bold" }}>Get the Answers That Matter</h4>
                <p className="mt-1" style={{ color: "#000000", fontSize: "14px" }}>Whether it’s about perfecting your personal statement, standing out in a competitive applicant pool, or understanding financial aid, ask the questions that matter most to you. We’ll deliver personalized advice to help you stand out and succeed.</p>
              </Col>
              
            </Row>
          </div>

        </Col>
      </Row>
    </GuestQuestionPageWrapper>
  );
};

export default GuestQuestionPage;