import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";

import pageSvg from "../assets/svg/forgot_password.svg";

export default function ForgotPassword() {
  document.body.classList.remove("sidebar-show");

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.student.youelite.net/api/forgot-password', { email });
      setMessage(response.data.message);
      setError(null);
      setEmailSent(true); // Set state to indicate email was sent
    } catch (err) {
      setMessage(null);
      setError(err.response?.data?.email || 'Something went wrong');
    }
  };

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link to="/" className="header-logo">YouElite</Link>
        </Container>
      </div>

      <div className="content">
        <Container>
          <Card className="card-auth">
            <Card.Body className="text-center">
              <div className="mb-5">
                <object type="image/svg+xml" data={pageSvg} className="w-50" aria-label="svg image"></object>
              </div>

              {emailSent ? (
                <>
                  <Card.Title>Check your email</Card.Title>
                  <Card.Text className="mb-5">We've sent a password reset link to your email. Please check your inbox.</Card.Text>
                </>
              ) : (
                <>
                  <Card.Title>Reset your password</Card.Title>
                  <Card.Text className="mb-5">Enter your email address and we will send you a link to reset your password.</Card.Text>
                  
                  {message && <Alert variant="success">{message}</Alert>}
                  {error && <Alert variant="danger">{error}</Alert>}
                  
                  <Form onSubmit={handleSubmit}>
                    <Row className="g-2">
                      <Col sm="8">
                        <Form.Control
                          type="email"
                          placeholder="Enter email address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </Col>
                      <Col sm="4">
                        <Button variant="primary" type="submit">Reset</Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
}
