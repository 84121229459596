import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import { verifyEmail, resendVerificationEmail, checkEmailVerification } from "../services/authService";
import pageSvg from "../assets/svg/mailbox.svg";

export default function VerifyAccount() {
  const [message, setMessage] = useState('Please check your email and click the verify button or link to verify your account.');
  const [isVerified, setIsVerified] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const hash = searchParams.get('hash');
    
    if (id && hash) {
      verifyEmail(id, hash)
        .then(response => {
          setMessage(response.data.message);
          setIsVerified(true);
          setTimeout(() => navigate('/profile-setup'), 3000);
        })
        .catch(error => {
          setMessage(error.response?.data?.message || 'Verification failed');
        });
    } else {
      const checkVerification = async () => {
        try {
          const result = await checkEmailVerification();
          if (result.data.verified) {
            setIsVerified(true);
            setMessage('Email verified successfully! Redirecting to profile setup...');
            setTimeout(() => navigate('/profile-setup'), 2000);
          }
        } catch (error) {
          console.error('Error checking verification:', error);
        }
      };

      const interval = setInterval(checkVerification, 5000); // Check every 5 seconds

      return () => clearInterval(interval);
    }
  }, [location, navigate]);

  const handleResendVerification = async () => {
    try {
      const response = await resendVerificationEmail();
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.message || 'Failed to resend verification email');
    }
  };

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link to="/" className="header-logo">YouElite</Link>
        </Container>
      </div>

      <div className="content">
        <Container>
          <Card className="card-auth">
            <Card.Body className="text-center">
              <div className="mb-5">
                <object type="image/svg+xml" data={pageSvg} className="w-50" aria-label="svg image"></object>
              </div>
              <Card.Title>Verify your email address</Card.Title>
              <Card.Text className="mb-5">
                {message}
              </Card.Text>

              {!isVerified && (
                <Row className="g-2 g-sm-3">
                  <Col sm>
                    <Button variant="primary" onClick={handleResendVerification}>Resend Verification</Button>
                  </Col>
                  <Col sm>
                    <Button variant="secondary">Contact Support</Button>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
}