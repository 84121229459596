import React from "react";

const sidebarShow = (e) => {
  document.querySelector("body").classList.toggle("sidebar-show");
}

export default function HeaderMobile() {
  const user = JSON.parse(localStorage.getItem('user'));
  const fullName = user ? `${user.name} ${user.surname}` : 'Guest';

  return (
    <div className="main-mobile-header doc-mobile-header">
      <div onClick={sidebarShow} className="menu-link"><i className="ri-menu-line"></i></div>
      <div className="mobile-user-info">
        <h5>{fullName}</h5>
      </div>
    </div>
  )
}
