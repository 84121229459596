import React, { useEffect, useState } from 'react';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { fetchNotifications } from '../services/notificationService';

import '../assets/css/NotificationsPage.css';

export default function NotificationsPage() {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const loadNotifications = async () => {
      const fetchedNotifications = await fetchNotifications();
      if (fetchedNotifications && fetchedNotifications.notifications) {
        setNotifications(fetchedNotifications.notifications);
      }
    };

    loadNotifications();
  }, []);

  const formatDate = (dateString) => {
    const options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <React.Fragment>
      <Header />

      <div className="notifications-page main main-app p-3 p-lg-4">
        <div className="page-content d-flex">
          <div className="main-content flex-grow-1">
            <div className="container">
              <h4 className="page-title mb-3">Notifications</h4>

              <div className="notifications-container">
                {notifications.length > 0 ? (
                  notifications.map((notification, index) => (
                    <div key={index} className="notification-item">
                      <div className="notification-icon">
                        <i className="ri-information-line"></i>
                      </div>
                      <div className="notification-content">
                        <p>{notification.data.message}</p>
                        <span>{formatDate(notification.created_at)}</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-notifications">
                    <div className="alert alert-info text-center">
                      <i className="ri-information-line"></i> No unread notifications available.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}