import React, { useState, useEffect } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';

const AcademicInterests = () => {
  const [formData, setFormData] = useState({
    preferredMajors: [],
    collegeTypes: '',
    preferredLocations: ''
  });
  const [focus, setFocus] = useState(0);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const selectOptions = [
    { value: 'Agriculture, Agriculture Operations, and Related Sciences', label: 'Agriculture, Agriculture Operations, and Related Sciences' },
    { value: 'Natural Resources and Conservation', label: 'Natural Resources and Conservation' },
    { value: 'Architecture and Related Services', label: 'Architecture and Related Services' },
    { value: 'Area, Ethnic, Cultural, Gender, and Group Studies', label: 'Area, Ethnic, Cultural, Gender, and Group Studies' },
    { value: 'Communication, Journalism, and Related Programs', label: 'Communication, Journalism, and Related Programs' },
    { value: 'Communications Technologies/Technicians and Support Services', label: 'Communications Technologies/Technicians and Support Services' },
    { value: 'Computer and Information Sciences and Support Services', label: 'Computer and Information Sciences and Support Services' },
    { value: 'Personal and Culinary Services', label: 'Personal and Culinary Services' },
    { value: 'Education', label: 'Education' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Engineering Technologies and Engineering-Related Fields', label: 'Engineering Technologies and Engineering-Related Fields' },
    { value: 'Foreign Languages, Literatures, and Linguistics', label: 'Foreign Languages, Literatures, and Linguistics' },
    { value: 'Family and Consumer Sciences/Human Sciences', label: 'Family and Consumer Sciences/Human Sciences' },
    { value: 'Legal Professions and Studies', label: 'Legal Professions and Studies' },
    { value: 'English Language and Literature/Letters', label: 'English Language and Literature/Letters' },
    { value: 'Liberal Arts and Sciences, General Studies and Humanities', label: 'Liberal Arts and Sciences, General Studies and Humanities' },
    { value: 'Library Science', label: 'Library Science' },
    { value: 'Biological and Biomedical Sciences', label: 'Biological and Biomedical Sciences' },
    { value: 'Mathematics and Statistics', label: 'Mathematics and Statistics' },
    { value: 'Military Technologies and Applied Sciences', label: 'Military Technologies and Applied Sciences' },
    { value: 'Multi/Interdisciplinary Studies', label: 'Multi/Interdisciplinary Studies' },
    { value: 'Parks, Recreation, Leisure, Fitness, and Kinesiology', label: 'Parks, Recreation, Leisure, Fitness, and Kinesiology' },
    { value: 'Philosophy and Religious Studies', label: 'Philosophy and Religious Studies' },
    { value: 'Theology and Religious Vocations', label: 'Theology and Religious Vocations' },
    { value: 'Physical Sciences', label: 'Physical Sciences' },
    { value: 'Science Technologies/Technicians', label: 'Science Technologies/Technicians' },
    { value: 'Psychology', label: 'Psychology' },
    { value: 'Homeland Security, Law Enforcement, Firefighting, and Related Protective Services', label: 'Homeland Security, Law Enforcement, Firefighting, and Related Protective Services' },
    { value: 'Public Administration and Social Service Professions', label: 'Public Administration and Social Service Professions' },
    { value: 'Social Sciences', label: 'Social Sciences' },
    { value: 'Construction Trades', label: 'Construction Trades' },
    { value: 'Mechanic and Repair Technologies/Technicians', label: 'Mechanic and Repair Technologies/Technicians' },
    { value: 'Precision Production', label: 'Precision Production' },
    { value: 'Transportation and Materials Moving', label: 'Transportation and Materials Moving' },
    { value: 'Visual and Performing Arts', label: 'Visual and Performing Arts' },
    { value: 'Health Professions and Related Programs', label: 'Health Professions and Related Programs' },
    { value: 'Business, Management, Marketing, and Related Support Services', label: 'Business, Management, Marketing, and Related Support Services' },
    { value: 'History', label: 'History' }
  ];
  


  useEffect(() => {
    const fetchUserData = async () => {
      const user = localStorage.getItem('user');
      if (!user) {
        setMessage('No authentication token found');
        setLoading(false);
        return;
      }

      const { token } = JSON.parse(user);

      try {
        const response = await axios.get('https://api.student.youelite.net/api/user', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        });
        const data = response.data;
        setFormData({
          preferredMajors: data.preferredMajors || '',
          collegeTypes: data.collegeTypes || '',
          preferredLocations: data.preferredLocations || ''
        });
        setFocus(data.focus || 0);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        setMessage('Failed to load academic interests');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFocusChange = (e) => {
    const newFocus = parseInt(e.target.value, 10);
    setFocus(newFocus);
  };


  const handleSelectChange = (selectedOptions) => {
    const majors = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setFormData((prevData) => ({
      ...prevData,
      preferredMajors: majors
    }));
  };




  const updateFocus = async (token, newFocus) => {
    try {
      await axios.put('https://api.student.youelite.net/api/user/focus', { focus: newFocus }, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      setMessage('Focus updated successfully');
    } catch (error) {
      console.error('Failed to update focus:', error);
      setMessage('Failed to update focus');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = localStorage.getItem('user');
    if (!user) {
      setMessage('No authentication token found');
      return;
    }

    const { token } = JSON.parse(user);

    try {
      await updateFocus(token, focus);
      await axios.put('https://api.student.youelite.net/api/user', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      setMessage('Academic interests updated successfully');
      setTimeout(() => {
        navigate('/academic-background');
      }, 2000);
    } catch (error) {
      console.error('Failed to update academic interests:', error);
      setMessage('Failed to update academic interests');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-sign">
      <Card className="card-sign" style={{ maxWidth: '700px', margin: 'auto' }}>
        <Card.Header>
          <Link to="/" className="header-logo mb-4">YouElite</Link>
          <Card.Title>Academic Interests</Card.Title>
          <Card.Text>Tell us about your academic interests.</Card.Text>
        </Card.Header>
        <Card.Body>
          {message && <div className="alert alert-info">{message}</div>}
          <Form onSubmit={handleSubmit}>
            <div className="mb-3">
              <Form.Label>Priorities when choosing colleges</Form.Label>
              <Form.Control as="select" value={focus} onChange={handleFocusChange}>
                <option value={0}>Balanced</option>
                <option value={1}>Academic focus</option>
                <option value={2}>Financial focus</option>
                <option value={3}>Career focus</option>
                <option value={4}>Social focus</option>
              </Form.Control>
            </div>
            <div className="mb-3">
              <Form.Label>Preferred Majors or Fields of Study</Form.Label>
              <Select
                options={selectOptions}
                isSearchable={true}
                isMulti
                value={selectOptions.filter(option => formData.preferredMajors.includes(option.value))}
                onChange={handleSelectChange}
              />
            </div>

            <div className="mb-3">
              <Form.Label>Type of Colleges Interested In</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter college types (e.g., Community College, Four-Year University)" 
                name="collegeTypes"
                value={formData.collegeTypes} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="mb-3">
              <Form.Label>Preferred College Locations</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter preferred college locations (cities/states)" 
                name="preferredLocations"
                value={formData.preferredLocations} 
                onChange={handleChange} 
                required 
              />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">Save and Continue</Button>
          </Form>
          <Button variant="link" onClick={() => navigate('/academic-background')} style={{color: '#007bff', textDecoration: 'underline', marginTop: '10px'}}>Skip</Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AcademicInterests;
