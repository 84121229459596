import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../services/authService"; // Ensure this function is available and correctly implemented
import bg1 from "../assets/img/bg1.jpg";

export default function Signup2() {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    // Validate passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      // Ensure the payload matches the backend API's expected structure
      const response = await register(name, surname, email, password, confirmPassword);
      setSuccess('Registration successful! Redirecting to email verification...');
      localStorage.setItem('user', JSON.stringify(response));
      setTimeout(() => {
        navigate('/verify');
      }, 1000); 
    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : 'Registration failed. Please check your details and try again.';
      setError(errorMessage);
      console.error('Registration error:', errorMessage);
    }
  };

  return (
    <div className="page-sign py-0">
      <Row className="g-0">
        <Col
          md="7"
          lg="5"
          xl="4"
          className="col-wrapper"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Ensure it takes up the full height of the viewport
            overflow: 'hidden', // Disable scrolling on the left column
          }}
        >
          <Card
            className="card-sign"
            style={{
              width: '100%',
              maxWidth: '400px',
              padding: '20px',
            }}
          >
            <Card.Header>
              <Link to="/" className="header-logo mb-4">YouElite</Link>
              <Card.Title>Sign Up</Card.Title>
              <Card.Text>It's free to signup and only takes a minute.</Card.Text>
            </Card.Header>
            <Card.Body>
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">{success}</div>}
              <Form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your first name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <Form.Label>Surname</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your surname"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm your password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <small>
                    By clicking <strong>Create Account</strong> below, you agree to our{' '}
                    <a href="https://www.youelite.net/terms-of-service" target="_blank" rel="noopener noreferrer">terms of service</a> and privacy statement.
                  </small>
                </div>
                <Button type="submit" variant="primary" className="btn-sign">Create Account</Button>
              </Form>

              <div className="divider" style={{display:"none"}}><span>or sign up using</span></div>

              <Row className="gx-2" style={{display:"none"}}>
                <Col>
                  <Button variant="" className="btn-facebook"><i className="ri-facebook-fill"></i> Facebook</Button>
                </Col>
                <Col>
                  <Button variant="" className="btn-google"><i className="ri-google-fill"></i> Google</Button>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              Already have an account? <Link to="/signin">Sign In</Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col
          className="d-none d-lg-block p-5"
          style={{
            backgroundColor: "#f9f9f9",
            borderLeft: "1px solid #e0e0e0",
            height: "100vh", // Full height of the viewport
            overflowY: "auto", // Allow vertical scrolling only on the right column
          }}
        >
      {/* Main Header Section */}
      <div className="header-section mt-3" style={{ textAlign: "center", marginBottom: "40px" }}>
        <h1 style={{ fontWeight: "bold", fontSize: "34px", marginBottom: "10px" }}>
          The most comprehensive
        </h1>
        <h1 style={{ fontWeight: "bold", fontSize: "34px", marginBottom: "20px" }}>
          User Management Platform
        </h1>
        <p style={{ color: "#6c757d", fontSize: "16px" }}>
          Need more than just a sign-in box? Clerk is a complete suite of embeddable UIs,
          flexible APIs, and admin dashboards to authenticate and manage your users.
        </p>
      </div>

      {/* Button Section */}
      <div className="button-section" style={{ textAlign: "center", marginBottom: "40px" }}>
        <Button variant="primary" style={{ marginRight: "10px", padding: "10px 20px" }}>
          Start building for free
        </Button>
        <Button variant="outline-dark" style={{ padding: "10px 20px" }}>
          Watch demo 2 min
        </Button>
      </div>

      {/* Trusted Logos Section */}
      <div className="trusted-section" style={{ textAlign: "center", marginBottom: "40px" }}>
        <p style={{ color: "#6c757d", marginBottom: "10px", fontSize: "14px" }}>
          Trusted by fast-growing companies around the world
        </p>
        <div className="logos" style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          {/* Placeholder logos */}
          <div style={{ width: "80px", height: "30px", backgroundColor: "#e0e0e0" }}></div>
          <div style={{ width: "80px", height: "30px", backgroundColor: "#e0e0e0" }}></div>
          <div style={{ width: "80px", height: "30px", backgroundColor: "#e0e0e0" }}></div>
          <div style={{ width: "80px", height: "30px", backgroundColor: "#e0e0e0" }}></div>
        </div>
      </div>

      {/* Lower Part: Pixel-perfect UIs section */}
      <div className="ui-example-section" style={{ marginTop: "40px" }}>
        <Row>
          <Col style={{ padding: "20px" }}>
            <h4 style={{ fontWeight: "bold" }}>Pixel-perfect UIs, embedded in minutes</h4>
            <p style={{ color: "#6c757d", fontSize: "14px" }}>
              Simply add <code>&lt;SignIn /&gt;</code>, <code>&lt;SignUp /&gt;</code>, <code>&lt;UserButton /&gt;</code>, and <code>&lt;UserProfile /&gt;</code> for complete user management functionality. Match to your brand with any CSS library, then deploy to your own domain—no more jarring redirects!
            </p>
          </Col>
          <Col style={{ padding: "20px" }}>
            {/* Placeholder for the UI image */}
            <div style={{ width: "100%", height: "150px", backgroundColor: "#e0e0e0", borderRadius: "10px" }}>
              {/* Placeholder box for UI component image */}
            </div>
          </Col>
        </Row>
      </div>

      {/* Lower Part: Additional Components Section */}
      <div className="additional-components-section" style={{ marginTop: "40px" }}>
        <Row>
          {/* Left Component */}
          <Col style={{ padding: "20px" }}>
            <h4 style={{ fontWeight: "bold" }}>Component One</h4>
            <div style={{ width: "100%", height: "150px", backgroundColor: "#e0e0e0", borderRadius: "10px" }}>
              {/* Placeholder for a UI component */}
            </div>
          </Col>

          {/* Right Component */}
          <Col style={{ padding: "20px" }}>
            <h4 style={{ fontWeight: "bold" }}>Component Two</h4>
            <div style={{ width: "100%", height: "150px", backgroundColor: "#e0e0e0", borderRadius: "10px" }}>
              {/* Placeholder for another UI component */}
            </div>
          </Col>
        </Row>
      </div>

      <div className="lower-part" style={{ padding: "40px", backgroundColor: "#f9f9f9" }}>
      <Row className="g-4" style={{ justifyContent: "space-between" }}>
        {/* Card 1: Custom roles and permissions */}
        <Col md={4}>
          <Card style={{ borderRadius: "15px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
            <Card.Body>
              <h5 style={{ fontWeight: "bold" }}>Custom roles and permissions</h5>
              <p style={{ color: "#6c757d", fontSize: "14px" }}>
                Powerful primitives to fully customize your app's authorization story.
              </p>
              <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "10px", marginBottom: "20px" }}>
                {/* Avatar placeholders */}
                {[...Array(6)].map((_, i) => (
                  <div key={i} style={{ width: "100%", height: "80px", backgroundColor: "#e0e0e0", borderRadius: "8px" }}></div>
                ))}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ fontSize: "12px" }}>Product Member</span>
                <span style={{ fontSize: "12px" }}>Administrator</span>
                <span style={{ fontSize: "12px" }}>Editor</span>
                <span style={{ fontSize: "12px" }}>QA Tester</span>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Card 2: Auto-join and Invitations */}
        <Col md={4}>
          <Card style={{ borderRadius: "15px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
            <Card.Body>
              <h5 style={{ fontWeight: "bold" }}>Auto-join</h5>
              <p style={{ color: "#6c757d", fontSize: "14px" }}>
                Let your users discover and join organizations based on their email domain.
              </p>
              <div style={{ width: "100%", height: "120px", backgroundColor: "#e0e0e0", borderRadius: "10px", marginBottom: "20px" }}>
                {/* Placeholder for auto-join graphic */}
              </div>
              <h5 style={{ fontWeight: "bold" }}>Invitations</h5>
              <p style={{ color: "#6c757d", fontSize: "14px" }}>
                Fuel your application's growth by making it simple for your customers to invite their team.
              </p>
              <div style={{ width: "100%", height: "80px", backgroundColor: "#e0e0e0", borderRadius: "10px", position: "relative" }}>
                <Button variant="dark" style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                  Invite this person
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Card 3: Organization UI Components */}
        <Col md={4}>
          <Card style={{ borderRadius: "15px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
            <Card.Body>
              <h5 style={{ fontWeight: "bold" }}>Organization UI Components</h5>
              <p style={{ color: "#6c757d", fontSize: "14px" }}>
                Clerk's UI components add turn-key simplicity to complex organization management tasks.
              </p>
              <div style={{ width: "100%", height: "180px", backgroundColor: "#e0e0e0", borderRadius: "10px", marginBottom: "20px" }}>
                {/* Placeholder for organization UI component */}
              </div>
              <Button variant="outline-primary" style={{ width: "100%" }}>
                Clerk
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>

    </Col>
      </Row>
    </div>
  );
}
