import React, { useState, useEffect } from 'react';
import { Nav, Breadcrumb } from 'react-bootstrap';
import ProfileTabs from '../tabs/ProfileTabs';

const { ProfileTab, EducationTab, ActivitiesTab, PackagesTab, SettingsTab } = ProfileTabs;

export default function Settings() {

    useEffect(() => {
        document.title = "Account Settings | YouElite";
      }, []);
    


    const [activeTab, setActiveTab] = useState('profile');

    return (
        <React.Fragment>
            <div className="main p-4 p-lg-5">
                <Breadcrumb className="fs-sm mb-2">
                    <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item active> Account Settings</Breadcrumb.Item>
                </Breadcrumb>
                <h2 className="main-title">Settings</h2>

                <Nav className="nav-line mb-4">
                    <Nav.Link onClick={() => setActiveTab('profile')} className={activeTab === 'profile' ? 'active' : ''}>Profile</Nav.Link>
                    <Nav.Link onClick={() => setActiveTab('education')} className={activeTab === 'education' ? 'active' : ''}>Education Background</Nav.Link>
                    <Nav.Link onClick={() => setActiveTab('activities')} className={activeTab === 'activities' ? 'active' : ''}>Activities</Nav.Link>
                    <Nav.Link onClick={() => setActiveTab('packages')} className={activeTab === 'packages' ? 'active' : ''}>Packages</Nav.Link>
                    <Nav.Link onClick={() => setActiveTab('settings')} className={activeTab === 'settings' ? 'active' : ''}>Settings</Nav.Link>
                </Nav>

                {activeTab === 'profile' && <ProfileTab />}
                {activeTab === 'education' && <EducationTab />}
                {activeTab === 'activities' && <ActivitiesTab />}
                {activeTab === 'packages' && <PackagesTab />}
                {activeTab === 'settings' && <SettingsTab />}
            </div>
        </React.Fragment>
    );
}
